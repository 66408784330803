import * as Yup from 'yup';
import { FormikProps } from 'formik';
import {
  ResponseUsersProps,
  UserAuth,
  User,
  CashRegisterAccess
} from '../models/User';
import { UsersStatus } from 'src/app/core/config/enums';

export interface ManagementUsersProps {
  users: ResponseUsersProps;
  getUsers: (
    page: number,
    limit: number,
    search: string | null
  ) => Promise<void>;
  deleteUser: (userId: object) => Promise<void>;
}

export interface UsersFormValues {
  _id?: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zipCode: string;
  dob: string;
  password?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isEnable: boolean;
  account?: Array<string>;
  role: string;
  image?: string;
  securityCode?: number;
  status: string;
  file?: any;
  canPrepare?: boolean;
  cashRegisters?: CashRegisterAccess[];
  permissionsPOS?: { label: string; value: string }[];
}

export interface ManagementUsersCreateProps {
  user: UserAuth;
  createUser: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
}

export interface ManagementUsersUpdateProps {
  user: User;
  userAuth: UserAuth;
  updateUser: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getUser: (id: string, callback: Function) => void;
}

export interface UsersFormProps {
  user: UserAuth;
  formikInstance: FormikProps<UsersFormValues>;
  cashRegisters?: CashRegisterAccess[];
  setCashRegisters?: (value: CashRegisterAccess[]) => void;
  permissionsPOS?: { label: string; value: string }[];
  setPermissionsPOS?: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        value: string;
      }[]
    >
  >;
}

export const initialValues: UsersFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  status: UsersStatus.ACTIVE,
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipCode: '',
  dob: '',
  password: '',
  isEnable: true,
  canPrepare: false,
  account: [],
  cashRegisters: [],
  permissionsPOS: []
};

export const validationSchema: Yup.SchemaOf<UsersFormValues> = Yup.object({
  _id: Yup.string(),
  firstName: Yup.string().required('Este campo es requerido'),
  lastName: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .required('Este campo es requerido')
    .email('Debe introducir un correo electrónico válido'),
  phone: Yup.string().required('Este campo es requerido'),
  role: Yup.string().required('Este campo es requerido'),
  status: Yup.string().required('Este campo es requerido'),
  documentId: Yup.string().required('Este campo es requerido'),
  country: Yup.string().required('Este campo es requerido'),
  state: Yup.string().required('Este campo es requerido'),
  city: Yup.string().required('Este campo es requerido'),
  address: Yup.string().required('Este campo es requerido'),
  zipCode: Yup.string().required('Este campo es requerido'),
  securityCode: Yup.number()
    .nullable(true)
    .test(
      'isNumber',
      'El campo debe ser un número',
      (value) => value == null || !isNaN(value)
    )
    .test(
      'minLength',
      'Mínimo 4 caracteres',
      (value) => value == null || value.toString().length >= 4
    )
    .notRequired(),
  dob: Yup.string().required('Este campo es requerido'),
  password: Yup.string().when('_id', (id: string) => {
    if (!id) {
      return Yup.string()
        .min(8, 'Mínimo 8 caracteres')
        .matches(/[0-9]/, 'Debe contener al menos un número')
        .matches(/[A-Z]/, 'Debe contener al menos una letra mayúscula')
        .matches(/[^\w]/, 'Debe contener al menos un caracter especial')
        .required('El campo es requerido.');
    } else {
      return Yup.string();
    }
  }),
  isEnable: Yup.bool().required('Este campo es requerido'),
  account: Yup.array(),
  image: Yup.string(),
  file: Yup.object().nullable(),
  canPrepare: Yup.boolean().nullable(),
  cashRegisters: Yup.array(
    Yup.object({
      cashRegisterId: Yup.string(),
      cashRegisterName: Yup.string()
    })
  ).nullable(),
  permissionsPOS: Yup.array(
    Yup.object({ label: Yup.string(), value: Yup.string() })
  ).nullable()
});

export const UserAuthInitialValues: UserAuth = {
  _id: '',
  accessToken: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipCode: '',
  dob: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isEnable: false,
  selectedAccount: '',
  account: [],
  accountList: [],
  role: '',
  image: '',
  status: '',
  canPrepare: false,
  allowedAccount: null,
  permissionsPOS: null
};

export const UserInitialValues: User = {
  _id: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  status: '',
  isEnable: false,
  dob: '',
  account: [],
  email: '',
  phone: '',
  role: '',
  image: '',
  createdBy: '',
  createdAt: '',
  deletedAt: '',
  canPrepare: false,
  __v: 0,
  cashRegisterAccess: [],
  permissionsPOS: null
};
