import { ReactNode } from 'react';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssistanceIcon from '@mui/icons-material/Hearing';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import GroupsIcon from '@mui/icons-material/Groups';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: DashboardTwoToneIcon
      },
      {
        name: 'Restaurante',
        link: '/account',
        icon: RestaurantIcon
      },
      {
        name: 'Asistencia al cliente',
        link: '/assistance',
        icon: AssistanceIcon
      },
      {
        name: 'Órdenes',
        link: '/account/orders',
        icon: ListTwoToneIcon
      },
      {
        name: 'Usuarios',
        icon: PeopleAltIcon,
        link: '/users'
      },
      {
        name: 'Transacciones',
        icon: TableChartTwoToneIcon,
        link: '/management/transactions'
      },
      {
        name: 'Perfil de Usuario',
        icon: AccountCircleTwoToneIcon,
        link: '/profile'
      },
      // {
      //   name: 'Reportes',
      //   icon: AssessmentIcon,
      //   link: '/report'
      // },
      {
        name: 'Reportes',
        icon: AssessmentIcon,
        items: [
          {
            name: '',
            link: '/report',
            items: [
              { name: 'Ventas', link: '/report/sales' },
              { name: 'Inventario', link: '/report/inventory' },
              { name: 'Colaboradores', link: '/report/employees' },
              { name: 'Órdenes', link: '/report/orders' }
            ]
          }
        ]
      },
      {
        name: 'Colaboradores',
        icon: GroupsIcon,
        link: '/employees'
      },
      {
        name: 'POS',
        icon: PointOfSaleIcon,
        link: 'https://pos.mozzolife.com/'
      }
    ]
  }
];

export default menuItems;
